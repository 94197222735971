import SectionHeader from "../../../../components/common/SectionHeader";
import VehicleCard from "./VehicleCard";

const Vehicles = ({ vehicles, selectedVehicle, setSelectedVehicle, routes }: any) => {
  return (
    <div className="absolute top-0 left-0 h-full w-[350px] p-3 z-20">
      <div className="bg-white rounded-lg h-full w-full overflow-hidden overflow-y-scroll">
        <div className="px-3">
            <SectionHeader title="Vehicles" isInput showBorder inputPlaceholder="Search by shipment Id"/>
            {
                vehicles?.map((vehicle: any) => (
                    <VehicleCard key={vehicle._id} vehicle={vehicle} activeCard={selectedVehicle || vehicles[0]?._id} setActiveCard={setSelectedVehicle} routes={routes}/>
                ))
            }
        </div>
      </div>
    </div>
  );
};

export default Vehicles;
