import { useState } from "react";
import vehicleIcon from "../../../../assets/icons/vehicle.svg";
import Button from "../../../../components/common/Button";
import UpdateVehicle from "../../../../modal/UpdateVehicle";

const VehicleCard = ({ vehicle, activeCard, setActiveCard, routes }: any) => {
  const { _id, number, model, route, capacity } = vehicle;
  const { diparture, destination } = route;
  const [openUpdateVehicleModel, setOpenUpdateVehicleModel] = useState(false);

  return (
    <>
      {openUpdateVehicleModel && (
        <UpdateVehicle
          openModal={openUpdateVehicleModel}
          setIsOpen={setOpenUpdateVehicleModel}
          vehicle={vehicle}
          routes={routes}
        />
      )}
      <div
        className={`px-3 py-2 mb-3 border  rounded-lg ${
          activeCard === vehicle._id ? "border-blue-500" : "border-gray"
        }`}
        onClick={() => setActiveCard(vehicle._id)}
      >
        <div className="flex flex-row justify-between">
          <div className="flex gap-3">
            <img src={vehicleIcon} alt="Vehicle Icon" />
            <h1 className="text-xs mt-1 text-gray-500 leading-4">Ship{_id.slice(0, 2)}...{_id.slice(-6)}</h1>
          </div>
          <Button
            label="Change Route"
            onClick={() => setOpenUpdateVehicleModel(true)}
            className="!w-auto px-2 !mt-0 !py-1 !bg-white !text-blue-500 border !border-blue-500 !capitalize"
          />
        </div>
        <h1 className="text-xs text-gray-500 leading-6">{number}</h1>
        <div className="flex items-center justify-between">
          <div className="flex text-xs text-gray-500">
            <h1>{diparture.city}</h1>&nbsp;&nbsp;
            <span>---</span>&nbsp;&nbsp;
            <h1>{destination.city}</h1>
          </div>
          <h1 className="text-xs text-gray-500">
            Max L.D.&nbsp;{capacity}&nbsp;Ton
          </h1>
        </div>
      </div>
    </>
  );
};

export default VehicleCard;
