import axiosInstance from "../axios/AxiosInstance";
import { addVehicleType, updateVehicleType } from "./type";

export const createVehicle = async (vehicle: addVehicleType) => {
    try {
        const response = await axiosInstance.post("/vehicle/add-vehicle", vehicle);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const fetchVehicles = async () => {
    try {
        const response = await axiosInstance.get("/vehicle/get-vehicles");
        return response.data;
    } catch (error) {
        return error;
    }
}

export const updateVehicle = async (vehicleId: string, vehicle: updateVehicleType) => {
    try {
        const response = await axiosInstance.put(`/vehicle/update-vehicle/${vehicleId}`, vehicle);
        return response.data;
    } catch (error) {
        return error;
    }
}