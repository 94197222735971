import React from "react";
import roundedWithBorder from "../../../../assets/icons/roundedWithBorder.svg";
import rounded from "../../../../assets/icons/rounded.svg";

const RouteInformation = ({ route }: any) => {
  const { diparture, destination, waypoints, distance, duration } = route;
  const { hours, minutes } = duration;
  const totalMinutes = duration.hours * 60 + duration.minutes;
  const colors = ["#E6F4FF", "#ADC6FF", "#1677FF", "#F3FF33", "#FF33A1"];
  const routes = [
    {
      city: diparture?.city,
      stopName: "Departure",
      percentage: 0,
      color: colors[0],
    },
    ...waypoints.map((waypoint: any, index: number) => {
      const waypointMinutes = waypoint.hours * 60 + waypoint.minutes;
      const percentage = (waypointMinutes / totalMinutes) * 100;

      return {
        ...waypoint,
        stopName:
          index === waypoints.length - 1 ? "Arrival" : `Stop${index + 1}`,
        percentage: parseFloat(percentage.toFixed(2)), // Add percentage rounded to 2 decimals
        color: colors[(index + 1) % colors.length],
      };
    }),
  ];

  return (
    <div className="absolute bottom-0 left-[350px] right-0 w-auto h-auto max-h-40 pr-3 pb-3 z-10">
      <div className="flex bg-white rounded-lg h-full px-4 py-2">
        <div className="w-2/5 px-2">
          {routes.map((route: any, index: number) => (
            <div key={index} className="flex">
              <div className="flex flex-col h-full items-center">
                {index === 0 && (
                  <img
                    src={roundedWithBorder}
                    alt="Rounded With Border"
                    className="mt-2"
                  />
                )}
                {routes.length !== index - 1 && index !== 0 && (
                  <>
                    <div className="border-l-2 border-dotted border-gray-300 h-2"></div>
                    <img src={rounded} alt="Rounded" />
                  </>
                )}
              </div>
              <div key={index} className="flex ml-2">
                <h1 className="text-xs text-gray-500 w-24 leading-6">
                  {route.stopName}
                </h1>
                <h1 className="text-xs leading-6">{route.city}</h1>
              </div>
            </div>
          ))}
        </div>
        <hr className="border border-1 border-black bg-white h-auto opacity-5" />
        <div className="w-3/5 px-2">
          <div className="flex text-xs items-center justify-between leading-6">
            <h1>Average Time Per Day</h1>
            <h1>
              {hours} Hrs {minutes} Mins
            </h1>
          </div>
          <div className="flex flex-col mt-3 ">
            {/* <div className="flex flex-row" >
              {routes.map((route: any, index: number) => (
                <>
                  <h1 className="w-full text-xs leading-6" style={{ width: `${route.percentage}%` }}>
                    {route.city}
                  </h1>
                </>
              ))}
            </div> */}
            <div className="flex flex-row justify-between">
              {routes.map((route: any, index: number) => (
                <div
                  key={index}
                  className={`flex-1 ${
                    index === 0
                      ? "mr-auto"
                      : index === routes.length - 1
                      ? "ml-auto text-end"
                      : ""
                  }`}
                >
                  <h1
                    className="w-full text-xs text-gray-500 leading-6"
                    style={
                      index !== routes.length - 1
                        ? { width: `${route.percentage}%` }
                        : {}
                    }
                  >
                    {route.city}
                  </h1>
                </div>
              ))}
            </div>
            <div className="relative flex py-2">
              {routes.map((route: any, index: number) => (
                <>
                  <div
                    className={`h-8`}
                    style={{
                      width: `${route.percentage}%`,
                      backgroundColor: route.color,
                    }}
                  >
                    { index !== 0 && <h1 className="text-black text-xs font-medium flex items-end ml-2 justify-start h-full leading-6">
                      {route.hours} Hrs
                    </h1>}
                  </div>

                  {index !== 0 && index !== routes.length - 1 && (
                    <div className="border-8 h-auto border-red-500" />
                  )}
                  {(index === 0 || index === routes.length - 1) && (
                    <div
                      className={`absolute top-0 ${
                        index === 0 ? "left-0" : "right-0"
                      } border border-dashed h-full border-gray-500`}
                    />
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteInformation;
