import React from 'react'
import SectionHeader from '../../../../components/common/SectionHeader';
import { ImageWithBorder } from '../../../../components/common/ImageWithBorder';
import fileIcon from "../../../../assets/icons/file.svg";
import historyIcon from "../../../../assets/icons/history.svg";
import vehicleIcon from "../../../../assets/icons/vehicle.svg";


const Routes = ({routes}: any) => {
  return (
    <div className="h-[calc(100vh-85px)] bg-white rounded-lg overflow-y-scroll">
    <div className="px-4 pb-3">
      <SectionHeader title="Routes" isInput showBorder inputPlaceholder='Search by Route No' />
      <div className="flex items-center justify-between">
        <h1 className="text-sm text-black opacity-65 leading-6">
          Routes
        </h1>
        <div className="flex gap-1">
          <ImageWithBorder src={fileIcon} alt="file" isBorder />
          <ImageWithBorder src={historyIcon} alt="history" />
        </div>
      </div>
     {routes.map(
        (
          route: { _id: string; diparture: string; destination: string; vehicles: any, duration: string },
          index: number
        ) => (
          <>
            <div key={index} className="flex flex-col py-4">
              <div className="flex w-full items-center">
                <h1 className="text-sm leading-6 mr-2 uppercase">ROUTE{route?._id.slice(0, 2)}...{route?._id.slice(-6)}</h1>
                <hr className="border border-1 border-black bg-white w-full opacity-45" />
              </div>
              <div className="flex flex-row justify-between">
                <h1 className="text-xs leading-6 text-gray-400">
                  {route.diparture} - {route.destination}
                </h1>
                <h1 className="text-xs leading-6 text-[#1677FF]">
                  View More
                </h1>
              </div>
            </div>
            {
              route?.vehicles && route?.vehicles?.slice(0, 3).map((item: any, index: number) => (
                <div key={index} className="flex flex-row justify-between mb-2">
                  <div className='flex w-full justify-between'>
                    <div className='flex gap-3 items-center'>
                      <img src={vehicleIcon} alt="vehicle" className='w-9 h-9 mt-3' />
                      <div>
                        <h1 className='text-sm leading-6'>ID: SHIP{item?._id.slice(0, 2)}...{item?._id.slice(-6)}</h1>
                        <h1 className='text-xs leading-6 text-black opacity-45'>{route.diparture} ---- {route.destination}</h1>
                      </div>
                    </div>
                    <div className='text-end'>
                      <h1 className='text-sm leading-6'>{item.number}</h1>
                      <h1 className='text-xs leading-6 font-medium text-black opacity-45'>Estimate Time: {route.duration.split(" ")[0]} Hrs</h1>
                    </div>
                  </div>
                </div>
              ))
            }
            {
              route?.vehicles && route?.vehicles.length > 3 && (
                <div className="flex text-start items-center gap-3 mt-2">
                  <img src={vehicleIcon} alt="vehicle" className='w-9 h-9 mt-3' />
                  <h1 className='text-sm text-black opacity-65 leading-6'>{route.vehicles.length - 3}+ Vehicles</h1>
                </div>
              )
            }
          </>
        )
      )}
     </div>
    </div>
  )
}

export default Routes