import React, { useEffect, useState } from "react";
import SectionHeader from "../../../../components/common/SectionHeader";
import Map from "../../../../components/common/Map";
import { getAllRoutes } from "../../../../service/route";
import { ImageWithBorder } from "../../../../components/common/ImageWithBorder";

import CreateRoute from "../../../../modal/CreateRoute";
import Vehicles from "./Vehicles";
import { fetchVehicles } from "../../../../service/vehicle";
import RouteInformation from "./RouteInformation";
import Routes from "./Routes";
import { useLoader } from "../../../../context/LoderContext";

const Route = () => {
  const [routes, setRoutes] = useState<any>();
  const [openCreateRouteModal, setOpenCreateRouteModal] = useState(false);
  const [vehicelsData, setVehiclesData] = useState<any>();
  const [selectedVehicle, setSelectedVehicle] = useState<string>();
  const [selectedVehicleData, setSelectedVehicleData] = useState<any>();
  const { showLoader, hideLoader } = useLoader();
  
  const getVehicles = async () => {
    const res = await fetchVehicles();
    setVehiclesData(res.data);
    setSelectedVehicle(res.data[0]?._id);
    setSelectedVehicleData(res.data[0]);
  };

  const getRoutes = async () => {
    const res = await getAllRoutes();
    setRoutes(res.data);
  };

  useEffect(() => {
    getRoutes();
    getVehicles();
    showLoader();
  }, []);

  const handleClick = () => {
    setOpenCreateRouteModal(true);
  };

  useEffect(() => {
    setSelectedVehicleData(vehicelsData?.find((vehicle: any) => vehicle._id === selectedVehicle));
  }, [selectedVehicle, vehicelsData])

  useEffect(() => {
    if (routes && vehicelsData && selectedVehicleData) {
      hideLoader();
    }
  }, [routes, vehicelsData, selectedVehicleData]);

  return (
    <>
      {openCreateRouteModal && <CreateRoute openModal={openCreateRouteModal} setIsOpen={setOpenCreateRouteModal} />}
      <div className="grid grid-cols-[70%_30%] h-full gap-3">
        {selectedVehicleData?.route && <div className=" flex flex-col h-full bg-white overflow-hidden rounded-lg">
          <div className="px-4 pb-3">
            <SectionHeader
              title="Route Planning"
              isButton
              onClick={() => handleClick()}
            />
          </div>
          <div className="relative bg-white h-full">
            <Map
              dipartureLatitude={selectedVehicleData?.route?.diparture?.latitude}
              dipartureLongitude={selectedVehicleData?.route?.diparture?.longitude}
              destinationLatitude={selectedVehicleData?.route?.destination?.latitude}
              destinationLongitude={selectedVehicleData?.route?.destination?.longitude}
              waypoints={
                selectedVehicleData?.route?.waypoints
                  ? selectedVehicleData.route.waypoints.slice(0, -1)
                  : []
              }
              showRouting
            />
            {vehicelsData && <Vehicles vehicles={vehicelsData} selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} routes={routes}/>}
            {selectedVehicleData?.route && <RouteInformation route={selectedVehicleData?.route} />}
          </div>
        </div>}
        {routes && <Routes routes={routes} />}
      </div>
    </>
  );
};

export default Route;
