import { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

import redLocation from "../../assets/icons/redLocation.svg";
import blueLocation from "../../assets/icons/blueLocation.svg";

interface Props {
  dipartureLatitude: number;
  dipartureLongitude: number;
  destinationLatitude: number;
  destinationLongitude: number;
  waypoints?: { latitude: number; longitude: number }[];
  showRouting?: boolean;
  setRouteInfo?: (value: any) => void;
}

const Map: React.FC<Props> = ({
  dipartureLatitude,
  dipartureLongitude,
  destinationLatitude,
  destinationLongitude,
  waypoints,
  showRouting,
  setRouteInfo,
}: Props) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      const map = L.map(mapContainerRef.current, {
        zoomControl: false,
        attributionControl: false,
      }).setView([destinationLatitude, destinationLongitude], 7);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      const defaultIcon = L.icon({
        iconUrl: blueLocation,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      });

      const waypointIcon = L.icon({
        iconUrl: redLocation,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      });

      const arrivedCoordinates: [number, number] = [
        dipartureLatitude,
        dipartureLongitude,
      ];
      const destinationCoordinates: [number, number] = [
        destinationLatitude,
        destinationLongitude,
      ];

      L.marker(arrivedCoordinates, {
        icon: defaultIcon,
      }).addTo(map);
      L.marker(destinationCoordinates, {
        icon: defaultIcon,
      }).addTo(map);

      waypoints?.forEach((stop: any) => {
        L.marker([stop.latitude, stop.longitude], { icon: waypointIcon }).addTo(map);
      });

      if (showRouting) {
        const stopageCoordinates = waypoints ?  [
          L.latLng(dipartureLatitude, dipartureLongitude), // Departure
          ...waypoints?.map((stop: any) => L.latLng(stop.latitude, stop.longitude)), // Stoppages
          L.latLng(destinationLatitude, destinationLongitude), // Destination
        ] : [
          L.latLng(dipartureLatitude, dipartureLongitude), // Departure
          L.latLng(destinationLatitude, destinationLongitude), // Destination
        ]
        const routingControl = L.Routing.control({
          waypoints: stopageCoordinates,
          routeWhileDragging: true,
          draggableWaypoints: false,
          createMarker: (waypoint: any) => {
            if (waypoint && waypoint.latLng) {
              return L.marker(waypoint.latLng, {
                icon: L.divIcon({ className: 'leaflet-div-icon', html: '' }),
                draggable: true,
              });
            } else if (waypoint && waypoint.latitude && waypoint.longitude) {
              return L.marker([waypoint.latitude, waypoint.longitude], {
                icon: L.divIcon({ className: 'leaflet-div-icon', html: '' }),
                draggable: true,
              });
            } else {
              return null;
            }
          },
          
          router: new L.Routing.OSRMv1({
            serviceUrl: "https://router.project-osrm.org/route/v1",
          }),
          show: false,
          lineOptions: {
            styles: [{ color: "#1677FF", weight: 5 }],
            extendToWaypoints: false,
            missingRouteTolerance: 10,
          },
        } as any).addTo(map);

        routingControl.on("routesfound", (e) => {
          const routes = e.routes;

          const minDistanceRoute = routes.reduce(
            (minRoute: any, currentRoute: any) => {
              return currentRoute.summary.totalDistance <
                minRoute.summary.totalDistance
                ? currentRoute
                : minRoute;
            }
          );

          const routeDetails = {
            name: minDistanceRoute.name,
            distance: `${(
              minDistanceRoute.summary.totalDistance / 1000
            ).toFixed(2)} Km`,
            time: `${Math.floor(
              minDistanceRoute.summary.totalTime / 3600
            )} hours ${Math.floor(
              (minDistanceRoute.summary.totalTime % 3600) / 60
            )} minutes`,
          };

          if (setRouteInfo) {
            setRouteInfo(routeDetails);
          }
        });
      }

      return () => {
        map.remove();
      };
    }
  }, [
    dipartureLatitude,
    dipartureLongitude,
    destinationLatitude,
    destinationLongitude,
    showRouting,
    setRouteInfo,
  ]);

  return (
    <div
      ref={mapContainerRef}
      style={{ width: "100%", height: "100%", zIndex: 10 }}
    />
  );
};

export default Map;
