import React, { useEffect, useState } from "react";
import Modal from "../components/common/Modal";
import Dropdown from "../components/common/Dropdown";
import Button from "../components/common/Button";
import { useLoader } from "../context/LoderContext";
import { getAllDrivers } from "../service/driver";
import { updateVehicle } from "../service/vehicle";

interface UpdateVehicleProps {
  openModal: boolean;
  setIsOpen: (value: boolean) => void;
  vehicle: any;
  routes: any;
}

const UpdateVehicle = (props: UpdateVehicleProps) => {
  const { openModal, setIsOpen, vehicle, routes } = props;
  const { showLoader, hideLoader } = useLoader();
  const [routeData, setRouteData] = useState<any[]>([]);
  const [updateVehicleData, setUpdateVehicleData] = useState<any>({
    route: "",
    driver: "",
  });
  const [driversData, setDriversData] = useState([]);

  const getRoutes = async () => {
      const filteredRoutesData = routes.map(
        (route: {diparture: string, destination: string, _id: string}) => ({
          label: route.diparture + " - " + route.destination,
          value: route._id,
        })
      )
  
      if (filteredRoutesData.length > 0) {
        filteredRoutesData.unshift({ label: "Select Route Name", value: "" });
      } else {
        filteredRoutesData.push({ label: "No Routes found", value: "" });
      }
      setRouteData(filteredRoutesData);
    }

  const getDrivers = async () => {
    showLoader();
      const res = await getAllDrivers();
      if(res.code === 200){
        const filterdDriversData = res.data.map(
          (driver: { name: string; contact_number: string; _id: any; }) => ({
            label: driver.name + " (" + driver.contact_number + ")",
            value: driver._id
          })
        )
        if (filterdDriversData.length > 0) {
          filterdDriversData.unshift({ label: "Select Driver Name", value: "" });
        } else {
          filterdDriversData.push({ label: "No Drivers found", value: "" });
        }
        setDriversData(filterdDriversData)
        hideLoader();
      }
    }

    const handleSubmit = async () => {
      showLoader();
      const data = await updateVehicle(vehicle._id, updateVehicleData);
      if(data.code === 200){
        window.location.reload();
        hideLoader();
        setIsOpen(false);
      }
    }

    useEffect(() => {
      getRoutes();
      getDrivers();
      setUpdateVehicleData(() => ({route: vehicle?.route?._id, driver: vehicle?.driver?._id }));
    }, [])  

  return (
    <Modal isOpen={openModal} onClose={() => setIsOpen(false)}>
      <div className="w-[400px] max-h-[80%] bg-white rounded-md p-1">
        <div className="w-full h-full p-4">
          <h1 className="text-lg font-bold">Change Vehicle Route</h1>
          <Dropdown
              data={routeData}
              isBorder
              isLabel
              label="Select Route Name"
              setSelectValue={(value) =>
                setUpdateVehicleData((prevState: any) => ({ ...prevState, route: value }))
              }
              selectedValue={updateVehicleData.route}
              // isDisabled={disableWarehouseDropdown}
            />
            <Dropdown
              data={driversData}
              isBorder
              isLabel
              label="Select Driver Name"
              setSelectValue={(value) =>
                setUpdateVehicleData((prevState: any) => ({ ...prevState, driver: value }))
              }
              selectedValue={updateVehicleData.driver}
              // isDisabled={disableWarehouseDropdown}
            />
            <Button
              label="Update Vehicle"
              onClick={() => handleSubmit()}
              className="w-full mt-4"
            />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateVehicle;
