import React, { useEffect, useState } from "react";
import Modal from "../components/common/Modal";
import Dropdown from "../components/common/Dropdown";
import Button from "../components/common/Button";
import Input from "../components/common/Input";
import Map from "../components/common/Map";
import { useLoader } from "../context/LoderContext";
import { getWarehouse } from "../service/warehouse";
import { createRoute, getRouteWaypoints, updateRoute } from "../service/route";
import DropdownCheckbox from "../components/common/DropdownCheckbox";

interface CreateRouteProps {
  openModal: boolean;
  setIsOpen: (value: boolean) => void;
}

const CreateRoute = (props: CreateRouteProps) => {
  const { openModal, setIsOpen } = props;
  const [warehouseData, setWarehouseData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [cordinate, setCordinate] = useState({
    dipartureLatitude: 0,
    dipartureLongitude: 0,
    destinationLatitude: 0,
    destinationLongitude: 0,
  });
  const [formData, setFormData] = useState<{
    diparture: string;
    destination: string;
  }>({
    diparture: "",
    destination: "",
  });
  const [selectedWaypoints, setSelectedWaypoints] = useState<any[]>([]);
  const [routeInfo, setRouteInfo] = useState<any>(null);
  const [warehouseId, setWarehouseId] = useState<string>("");
  const [routeWaypoints, setRouteWaypoints] = useState<any[]>([]);
  const [disableWarehouseDropdown, setDisableWarehouseDropdown] = useState(false);
  const [showCreateRouteModal, setShowCreateRouteModal] = useState(true);

  const { showLoader, hideLoader } = useLoader();

  const handleSubmit = async () => {
    const payload = {
      ...formData,
      ...({ waypoints: [
        { _id: formData.diparture },
        ...selectedWaypoints.map(waypoint => ({ _id: waypoint })),
        { _id: formData.destination }
    ] }),
    };
    console.log(payload);
    showLoader();
    const response = await createRoute(payload);
    hideLoader();
    if (response.code === 200) {
      setShowConfirmModal(true);
      setWarehouseId(response.data._id);
      setShowCreateRouteModal(false);
    }
  };

  const isFormValid = () => {
    return (
      formData.diparture &&
      formData.destination
    );
  };

  const getAllWarehouse = async () => {
    showLoader();
    const warehouse = await getWarehouse();
    hideLoader();
    if (warehouse.code === 200) {
      const filteredWarehouseData = warehouse.data.map(
        (item: { address: { city: string, latitude: string, longitude: string }; name: string; _id: string }) => ({
          label: item.name,
          value: item._id,
          city: item.address.city,
          latitude: item.address.latitude,
          longitude: item.address.longitude,
        })
      );

      if (filteredWarehouseData.length > 0) {
        filteredWarehouseData.unshift({ label: "Select Warehouse", value: "" });
      } else {
        filteredWarehouseData.push({ label: "No warehouse found", value: "" });
      }

      setWarehouseData(filteredWarehouseData);
    }
  };

  const fetchRouteWaypoints = async () => {
    const routeWaypoints = await getRouteWaypoints(warehouseId);
    setRouteWaypoints(routeWaypoints.data.waypoints);
    console.log(routeWaypoints);
  }

  // useEffect(() => {
  //   if (formData.diparture !== "" && formData.destination !== "") {
  //     showLoader();
  //     setDisableWarehouseDropdown(true);
  //     const departureWarehouseCordinates = warehouseData.find(
  //       (item) => item.value === formData.diparture
  //     );
  //     const destinationWarehouseCordinates = warehouseData.find(
  //       (item) => item.value === formData.destination
  //     );
  //     if (departureWarehouseCordinates && destinationWarehouseCordinates) {
  //       const { latitude: arrivedLatitude, longitude: arrivedLongitude } =
  //         departureWarehouseCordinates;
  //       const {
  //         latitude: destinationLatitude,
  //         longitude: destinationLongitude,
  //       } = destinationWarehouseCordinates;
  //       setCordinate({
  //         arrivedLatitude: arrivedLatitude ?? 0,
  //         arrivedLongitude: arrivedLongitude ?? 0,
  //         destinationLatitude: destinationLatitude ?? 0,
  //         destinationLongitude: destinationLongitude ?? 0,
  //       });
  //     }
  //   }
  // }, [formData.diparture, formData.destination]);

  // useEffect(() => {
  //   hideLoader();
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     distance: routeInfo?.distance,
  //     duration: routeInfo?.time,
  //   }));
  // }, [routeInfo]);

  const handleConfirmButton = async () => {
    showLoader();
    const result = await updateRoute(warehouseId, { is_verified: true });
    if(result.code === 200){
      hideLoader();
      setShowConfirmModal(false);
      setIsOpen(false);
    }
  }

  useEffect(() => {
    getAllWarehouse();
  }, []);

  useEffect(() => {
    if(showConfirmModal){
      fetchRouteWaypoints();
    }
  }, [showConfirmModal])

  return (
    <>
    {showCreateRouteModal && <Modal isOpen={openModal} onClose={() => setIsOpen(false)}>
      <div className="w-[400px] max-h-4/5 bg-white rounded-md p-1">
        <div className="w-full h-full p-4 overflow-y-scroll">
          <Dropdown
            data={warehouseData}
            isBorder
            isLabel
            label="Select Departure Warehouse"
            setSelectValue={(value) =>
              setFormData((prevState) => ({ ...prevState, diparture: value }))
            }
            selectedValue={formData.diparture}
            isDisabled={disableWarehouseDropdown}
          />
          <Dropdown
            data={warehouseData}
            isBorder
            isLabel
            label="Select Destination Warehouse"
            setSelectValue={(value) =>
              setFormData((prevState) => ({ ...prevState, destination: value }))
            }
            selectedValue={formData.destination}
            isDisabled={disableWarehouseDropdown}
          />
          {cordinate.dipartureLatitude !== 0 &&
            cordinate.destinationLatitude !== 0 && (
              <Map
                dipartureLatitude={cordinate.dipartureLatitude}
                dipartureLongitude={cordinate.dipartureLongitude}
                destinationLatitude={cordinate.destinationLatitude}
                destinationLongitude={cordinate.destinationLongitude}
                showRouting
                setRouteInfo={setRouteInfo}
              />
            )}
          <DropdownCheckbox options={warehouseData} label="Select Waypoints" selectedValue={selectedWaypoints} setSelectedValue={setSelectedWaypoints}/>
          <Button
            label="Create Route"
            onClick={handleSubmit}
            disabled={!isFormValid()}
          />
        </div>
      </div>
    </Modal>}
    {
      showConfirmModal && (
        <Modal isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
          <div className="w-[400px] max-h-4/5 bg-white rounded-md p-1">
            <div className="w-full h-full p-4 overflow-y-scroll">
              {
                routeWaypoints.map((waypoint, index) => (
                  <div key={index} className="grid grid-cols-[40%_30%_30%] items-center p-2 border-b border-gray-300">
                    <div>{waypoint.routeName}</div>
                    <div className="text-center">{waypoint.distance} Km</div>
                    <div className="text-center">{waypoint.hours} Hours : {waypoint.minutes} Minutes</div>
                  </div>
                ))
              }
              <Button
                label="Confirm"
                onClick={() => handleConfirmButton()}
              />
            </div>
          </div>
        </Modal>
      )
    }
    </>
  );
};

export default CreateRoute;
